<template>
  <div class="four-o-four">
    <main id="four-o-four">
      <section class="four-o-four-section">
        <h1 class="login-title">You don't have permissions</h1>
      </section>
    </main>
  </div>
</template>

<script>
import router from '../router'

export default {
  name: 'four-o-four',
  methods: {
    goToLogin () {
      router.push('/login')
    }
  }
}      

</script>

<style src="@/assets/styles/views/UserAdministration.css"></style>
